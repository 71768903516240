import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthUseCaseService } from '../../../auth/application/auth-use-case.service';

export const userHasMaintenanceGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const authUseCaseService = inject(AuthUseCaseService);
  let userPermissions = authUseCaseService.user()?.permissions;

  while (!userPermissions) {
    // This is a workaround to avoid the guard to be executed before the user is loaded
    await new Promise((resolve) => setTimeout(resolve, 1));
    userPermissions = authUseCaseService.user()?.permissions;
  }

  const moduleNameList = userPermissions?.map((module) =>
    module.moduleName.toLowerCase()
  );

  if (
    moduleNameList?.includes('maintenance') ||
    moduleNameList?.includes('administrators') ||
    moduleNameList?.includes('systems')
  ) {
    return true;
  }

  router.navigate(['/main']);
  return false;
};
